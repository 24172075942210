
import { defineComponent, onMounted, ref } from "vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import { TODAY } from "@/core/data/events";
import { Modal } from "bootstrap";
import esLocale from "@fullcalendar/core/locales/th";
import axios from "axios";
import { useRouter } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
export default defineComponent({
  name: "event",
  components: {
    FullCalendar,
  },
  
  setup() {
    const router = useRouter();
    const events = ref();
    // const newEvent = () => {
    //   const modal = new Modal(document.getElementById("kt_modal_add_event"));
    //   modal.show();
    // };
    onMounted(() => {
      setCurrentPageBreadcrumbs("กิจกรรม", []);
    });

    const calendarOptions = {
      locale: esLocale,
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
      headerToolbar: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay",
      },
      initialDate: TODAY,
      navLinks: true, // can click day/week names to navigate views
      selectable: true,
      selectMirror: true,

      views: {
        dayGridMonth: { buttonText: "เดือน" },
        timeGridWeek: { buttonText: "สัปดาห์" },
        timeGridDay: { buttonText: "วัน" },
      },
      editable: true,
      displayEventTime: false,
      dayMaxEvents: true, // allow "more" link when too many events
      events: function(info, successCallback, failureCallback) {
        axios
          .get(process.env.VUE_APP_API_URL + "/getEvent?sizeContents=99999", {
            headers: {
              token: localStorage.getItem("id_token") ? localStorage.getItem("id_token") : localStorage.getItem("tokenGuest")
            },
          })
          .then(({ data }) => {
            successCallback(
              Array.prototype.slice
                .call(
                  // convert to array
                  data.data.content
                )
                .map(function(item) {
                  return {
                    id: item.id,
                    title: item.title,
                    start: new Date(item.startDate),
                    end: new Date(item.endDate),
                    // backgroundColor: item.type == 1 ? "#FB9E9E" : "#6BB3EE",
                  };
                })
            );
          })
          .catch(({ error }) => {
            failureCallback(error);
          });
      },
      eventClick(event, jssEvent, view) {
        router.push({
          name: "event-detail",
          params: { id: event.event._def.publicId },
        });
      },
    };
    return {
      calendarOptions,
      // newEvent,
      events,
    };
  },
});
